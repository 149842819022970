import React, { Component } from 'react'

export default class Page404 extends Component {
    render() {
        return (
            <div>
                <h1>
                    Page not found!
                </h1>
            </div>
        )
    }
}
